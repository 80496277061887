import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "hi"
    }}>{`Hi.`}</h1>
    <p>{`I'm Bong - currently I work at `}<a parentName="p" {...{
        "href": "https://safetyculture.com/",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`SafetyCulture`}</a>{` as a senior software engineer. Although my focus is on distributed systems with `}<a parentName="p" {...{
        "href": "https://golang.org/",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`Go`}</a>{`, I also spend some time in frontend stuffs like building my personal website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      